<template>
  <MarketsTicker/>
</template>

<script>
import { MarketsTicker } from 'global-components';
export default {
  name: 'markets-ticker',
  components: {
    MarketsTicker
  }
};
</script>
